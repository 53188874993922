<template>
  <div>
    <div class="top flex-jb-al">
      <div class="flex-jb-al">
        <img src="../assets/images/vip.png" alt>
        <div style="margin-left:75px">
          <!-- <p>ID：6545684948948484</p> -->
          <!-- <p class="no-vip mt-10">未开通会员</p> -->
        </div>
      </div>
      <div class="flex-al">
        <span>会员特权</span>
        <img style="margin-left:4px;" src="../assets/images/i.png" alt>
      </div>
    </div>
    <el-card
      class="pr"
      style="min-height:calc(100vh - 246px);margin-top:-30px;border-top: 2px solid #1f2637;"
    >
      <div class="triangle pa"></div>
      <div style="padding:0 60px">
        <!-- 充值 -->
        <div v-if="this.type=='recharge'">
          <div class="charge flex">
            <div
              class="item flex"
              :class="{'item-active':tabindex==item.id}"
              v-for="item in chargedata"
              :key="item.id"
              @click="chargeTab(item.id)"
            >
              <div class="flex-al" style="  border-right: 1px solid #e6e6e6;">
                <span class="cz">{{item.name}}</span>
              </div>

              <div class="give">
                <p>¥{{item.pay_price}}</p>
                <span v-if="item.price - item.pay_price>0">送{{item.price - item.pay_price}}元</span>
              </div>
            </div>
          </div>
          <div class="go-re" @click="goRe()">购买</div>
          <!-- <div class="pr vip-tip">
            <div class="pa">会员特惠充值，充的多送的多&nbsp&nbsp&nbsp&nbsp 活动规则</div>
            <div class>会员享年费9折优惠，终身制VIP，智易宝业务优先</div>
          </div>-->
        </div>
        <!-- 支付 -->
        <div v-if="payShow">
          <div class="pay-mode flex">
            <span class="mr-20 f14 color-0">选择支付方式：</span>
            <div class="vip-pay mr-30 t-c pr" @click="checkType(1)" v-if="this.type=='no_num'&& this.typeId!=3">
              <div style="margin-top:10px" class="flex-jc-alc">
                <img class="mr-10" src="../assets/images/vv.png" alt>
                <div>
                  <span>预付会员卡支付</span>
                  <div class="f12">余额：{{amount||'0.00'}}元</div>
                </div>
              </div>

              <img
                v-show="pay_type==1"
                class="pa"
                style="bottom:0px;right:0px;"
                src="../assets/images/rb.png"
                alt
              >
            </div>
            <div v-if="price!='0.00'" class="ali mr-30 flex-jc-alc pr" @click="checkType(3)">
              <img src="../assets/images/ali.png" alt>
              <span>支付宝支付</span>
              <img
                v-show="pay_type==3"
                class="pa"
                style="bottom:0px;right:0px;"
                src="../assets/images/rb.png"
                alt
              >
            </div>
            <div v-if="price!='0.00'" class="wx flex-jc-alc pr" @click="checkType(2)">
              <img src="../assets/images/wx.png" alt>
              <span>微信支付</span>
              <img
                v-show="pay_type==2"
                class="pa"
                style="bottom:0px;right:0px;"
                src="../assets/images/rb.png"
                alt
              >
            </div>
          </div>
          <div class="code pr">
            <div style=" margin-left:143px;" v-show="!showCode">
              <div
                class="f24 mt-30"
                style="color:#FF8547"
              >{{pay_type==1?'预付会员卡支付':pay_type==2?'微信支付':pay_type==3?'支付宝支付':''}}</div>
              <div class="mt-30">
                <span class="f18 color-0 bd mr-20">支付金额</span>
                <span class="f18" style="color:#f13d3d">¥{{price}}</span>
              </div>
              <div class="y-pay t-c mt-30" @click="bpay()">支付</div>
            </div>

            <div v-show="showCode">
              <div>
                <span class="f18 bd color-0">扫码购买</span>
                <span class="f14 ml-20" v-show="pay_type==3">使用支付宝APP扫码完成支付</span>
                <span class="f14 ml-20" v-show="pay_type==2">使用微信APP扫码完成支付</span>
              </div>
              <div style="margin-top:16px;" class="flex">
                <div style="padding:10px;background: #c4c4c4; width:130px;height:130px;">
                  <div style="padding:10px;background: #fff;width:110px;height:110px;">
                    <div class="qrcode" ref="qrCodeUrl"></div>
                  </div>
                </div>
                <div class="ml-30 pay-sum">
                  <p class="f14 color-0 bd">支付金额</p>
                  <p class="pay-price">¥{{price}}</p>
                </div>
              </div>
            </div>
            <div class="f14 agreement pa">《知易宝会员服务协议》</div>
          </div>
        </div>
      </div>
    </el-card>
     <!-- 余额输入密码 -->
    <el-dialog
      top='30vh'
      :before-close="handleClose"
      center
      title="预付会员卡支付"
      :visible.sync="dialogVisible"
      width="400px"
     >
           <div class="t-c f12"><span class="cur" @click="$router.push('/account')">忘记密码？去个人中心重新设置 <i class="el-icon-arrow-right"></i></span> </div>

      <div class="mt-30">
        <el-form
          ref="form1"
          label-width="80px"
          hide-required-asterisk
        >
          <el-form-item
            label="支付密码"
          >
            <el-input placeholder="请输入密码" autofocus v-model="password" autocomplete="off" show-password></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="zfComfrim" >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  created() {
    let type = this.$route.query.type;
    if (type != "recharge" && type != "no_num") {
      this.$router.replace("/index");
    }
    this.type = type;
    this.price = Number(this.$route.query.price).toFixed(2);
    if (type == "recharge") {
      this.memberCard();
    }
    if (type == "no_num") {
      this.order_no = this.$route.query.order_no;
      this.payShow = true;
      this.typeId=this.$route.query.typeId||''
      if(this.typeId==3){
        this.pay_type=3
      }
    }
  },
  mounted() {
    this.getMember();
  },
  data() {
    return {
      password:'',//支付密码
      typeId:'',
      type: "",
      price: "0.00",
      payinfo: "",
      pay_type: 1,
      order_no: "",
      tabindex: "",
      chargedata: [],
      timer1: null,
      payShow: false,
      amount: "",
      second: 0,
      showCode: false,
      dialogVisible:false,//支付密码
    };
  },
  methods: {
    // 获取充值列表
    memberCard() {
      this.$request.post("/client/member_card/listData").then(res => {
        if (res.data.code == 200) {
          this.chargedata = res.data.data;
          this.tabindex = this.chargedata[0].id;
        }
      });
    },

    checkType(index) {
       window.clearTimeout(this.timer1);
      this.pay_type = index;
      this.second = 0;
      this.showCode=false
      window.clearTimeout(this.timer1);
      // if (this.pay_type == 2 || this.pay_type == 3) {
      //   this.pay();
      // }
    },
    creatQrCode() {
      this.$refs.qrCodeUrl.innerHTML = "";
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.payinfo,
        width: 110,
        height: 110,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    // 支付类型 1余额 2微信3支付宝
    bpay(){
       window.clearTimeout(this.timer1);
      this.second = 0;
      if(this.pay_type==1){
        if(this.$store.state.infor.pay_pass_status==0){
            this.$confirm(`去个人中心设置支付密码！`, "未设置支付密码", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
                this.$router.push('/account')
              })
              .catch(() => {})
               return
               }
        this.dialogVisible=true 
        return
        }
        
       
      
      this.pay()
    },
    pay() {
      window.clearTimeout(this.timer1);
      this.second = 0;
      this.$request
        .post("/pay", {
          order_no: this.order_no,
          pay_type: this.pay_type,
          pay_password:this.password
        })
        .then(res => {
          if (res.data.code == 200) {
            if (this.pay_type == 2 || this.pay_type == 3) {
              this.showCode=true
              this.payinfo = res.data.data.pay.payinfo;
              this.price =res.data.data.orders.price -res.data.data.orders.coupon_price <= 0? "0.00": (res.data.data.orders.price -res.data.data.orders.coupon_price).toFixed(2);
              this.creatQrCode();
              this.getResult();
            }
            if (this.pay_type == 1) {
              setTimeout(() => {
                this.$router.replace("/order");
              }, 2000);
            }
          }
        });
    },
    getResult() {
      this.$request
        .get(`/pay/index/payStatus?order_no=${this.order_no}`)
        .then(res => {
          if (res.data.data.status == 1) {
            this.$message({
                  message: '支付成功',
                  type: 'success'
           });
            
            window.clearTimeout(this.timer1);
            window.setTimeout(() => {
              this.$router.replace("/order");
            }, 2000);
          } else {
            if (this.second == 100) {
              window.clearTimeout(this.timer1);
              this.second = 0;
              this.$router.replace("/order");
              return;
            }
            this.timer1 = window.setTimeout(() => {
              this.second += 1;
              this.getResult();
            }, 3000);
          }
        });
    },
    chargeTab(index) {
      if (this.tabindex == index) {
        return;
      }
      this.second = 0;
      window.clearTimeout(this.timer1);
      this.tabindex = index;
      this.payShow = false;
      this.showCode=false
    },
    goRe() {
      this.pay_type = "";
      this.$request
        .post("/client/member_card/pay", { id: this.tabindex })
        .then(res => {
          if (res.data.code == 200) {
            this.order_no = res.data.data.order_no;
            this.price=res.data.data.price
            this.pay_type = 3;
            this.payShow = true;
            // this.pay();
          }
        });
    },
    // 获取用户信息
    getMember() {
      this.$request.get("/client/member/info").then(res => {
        if (res.data.code == 200) {
          this.amount = res.data.data.memberInfo.amount;
        }
      });
    },
    //关闭密码
    handleClose(){
        this.password=''
        this.dialogVisible=false
    },
    // zhifumama
    zfComfrim(){
      if(this.password==''){
        this.$message('请输入密码')
        return
      }
      this.pay()
    }
  
  },
  beforeDestroy() {
    window.clearTimeout(this.timer1);
  }
};
</script>
<style scoped>
.top {
  height: 140px;
  background: #1f2637;
  border-radius: 8px;
  padding: 0 48px;
  color: #fff;
  font-size: 14px;
}
.no-vip {
  width: 94px;
  height: 24px;
  background: #ffffff;
  border-radius: 28px;
  color: #ffcb37;
  text-align: center;
  line-height: 24px;
}
.triangle {
  width: 0;
  border-top: 20px solid #1f2637;
  border-left: 16px solid white;
  border-right: 16px solid white;
  top: 0px;
  left: 150px;
}
.charge {
  flex-wrap: wrap;
}
.charge .item {
  width: 220px;
  height: 80px;
  border: 1px solid #e6e6e6;
  border-radius: 9px;
  color: #000000;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.charge .item.item-active {
  background-color: #ff293e;
  color: #fff;
}
.cz {
  display: inline-block;
  width: 60px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 0px 21px;
  box-sizing: border-box;
}
.give {
  flex: 1;
  text-align: center;
}
.give span {
  display: inline-block;
  padding: 0 6px;
  height: 19px;
  background: #e6e6e6;
  border-radius: 16px;
  font-size: 11px;
  color: #686868;
  text-align: center;
  line-height: 19px;
}
.give p {
  margin-top: 18px;
}
.item-active .give span {
  color: #fff;
  background: #ff3e51;
}
.vip-tip {
  margin-top: 62px;
}
.vip-tip div:first-child {
  width: 297px;
  height: 32px;
  background: #ff8547;
  border-radius: 8px 8px 8px 0px;
  font-size: 12px;
  color: #fff;
  line-height: 32px;
  padding-left: 25px;
  top: -16px;
}
.vip-tip div:last-child {
  height: 84px;
  background: #f2f3f5;
  border-radius: 8px;
  line-height: 84px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 700;
  color: #f13d3d;
}
.pay-mode {
  margin-top: 45px;
  padding-left: 20px;
}
.ali,
.wx,
.vip-pay {
  width: 180px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 9px;
  cursor: pointer;
}
.ali img:first-child,
.wx img:first-child {
  margin-right: 20px;
}
.code {
  height: 229px;
  background: #f2f3f5;
  border-radius: 8px;
  margin-top: 80px;
  padding: 20px 48px;
  color: #979797;
}
.pay-price {
  margin-top: 22px;
}
.pay-sum {
  margin-top: 50px;
  font-size: 20px;
  color: #f13d3d;
}
.agreement {
  bottom: 20px;
  right: 30px;
}
.y-pay {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background-color: #2962ff;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}
.go-re {
  width: 100px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background-color: #ff8547;
  border-radius: 10px;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
}
</style>

